<template>
  <div class="about">
    <div class="card shadow" style="display:inline-block;margin-top:5em;padding:3em;text-align:center;width:50%;border-radius:3em;">
      <img alt="Display Dog Logo" src="@/assets/img/displaydog-logo-500.svg" />
    <h1>About Us</h1>
    <p>Digital Signage Suite</p>
    <hr>
    <h2 style="margin-top:100px;">Work In Progress</h2>
    <p style="display:inline-block;text-align:center;width:50%;">
      This is a work in process and will change as we need more tools.  If you have ideas or things you would
      like to see in here, add a helpdesk ticket.
    </p>
    </div>
    <hr style="margin-top:200px;">
    <footer class="footer">
    &copy; Copyright 2019-Present, DisplayDog, all rights reserved <small>(an IOIHAN Company)</small>
    </footer>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'about',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  async mounted() {

  },
  methods: {
    
  }
}
</script>
